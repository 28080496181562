<template>
    <div class="small_red_packet_">
        小红包
    </div>
</template>

<script>
export default {
    
}
</script>

<style lang="scss" scoped>
    .small_red_packet_{
        color: red;
    }
</style>