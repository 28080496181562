import { render, staticRenderFns } from "./SmallRedPacket.vue?vue&type=template&id=3c2ed986&scoped=true&"
import script from "./SmallRedPacket.vue?vue&type=script&lang=js&"
export * from "./SmallRedPacket.vue?vue&type=script&lang=js&"
import style0 from "./SmallRedPacket.vue?vue&type=style&index=0&id=3c2ed986&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c2ed986",
  null
  
)

export default component.exports