<template>
  <div class="scrollaction">
    <vue-seamless-scroll
      :data="orderlist"
      :class-option="defaultOption"
      class="warp"
    >
     
      <ul  class="scrollbox">
        <li class="scrollitem" v-for="item in orderlist" :key="item.order_sn">
          <span  class="title">{{ item.user }}</span>
          <span class="region">{{ item.order_sn }}</span>
          <span class="name">{{ item.pay_at }}</span>
          <span v-if="type=='jiqi'" class="date">{{ item.price }}元</span>
        </li>
      </ul>
    </vue-seamless-scroll>
  </div>
</template>
<script>
import vueSeamlessScroll from "vue-seamless-scroll";
import {$ekaf} from "../api/component"
export default {
  name:"orderscroll",
  components: {
    vueSeamlessScroll,
  },
  data(){
    return{
      orderlist: []
    }
  },
  props:{
    type:{
      type:String,
      default:'jiqi'
    },
    prices:{
      type:Object,
      default:'19.90'
    }
  },
  computed:{
    defaultOption() {
      return {
        step: 0.5, // 数值越大速度滚动越快
        limitMoveNum: this.orderlist.length,
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: false, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
        openTouch: false,
      };
    },
  },
  async created(){
	  let postdata = {
      type: 2,
      perPage: 15,
      page: 1,
      atype:2,
      fromk:this.$route.query.from
    }
    if(this.type == 'testname'){
      postdata.atype = 2
    }
    let info = await $ekaf(postdata );
    this.orderlist = info.data.data;
    switch ('/'+this.$route.path.split('/')[1]) { //埋点
          case '/home_marriage_test':
            for ( let i = 0; i < this.orderlist.length; i++ ) {
              this.orderlist[i].price = this.prices.marriage_amount 
            }
              break; 
          case '/home_marriage_test_v2':
            for ( let i = 0; i < this.orderlist.length; i++ ) {
              this.orderlist[i].price = this.prices.marriage_amount 
            }
              break;
          case '/home_marriage_test_v3':
            for ( let i = 0; i < this.orderlist.length; i++ ) {
              this.orderlist[i].price = this.prices.marriage_amount 
            }
              break;
          case '/name_matching':
            for ( let i = 0; i < this.orderlist.length; i++ ) {
              this.orderlist[i].price = this.prices.pairname_amount 
            }
              break;
      
          default:
              break;
      }
    // for ( let i = 0; i < this.orderlist.length; i++ ) {
    //   this.orderlist[i].price = this.prices.marriage_amount 
    // }
  }
}
</script>

<style scoped>
.scrollaction {
  margin: 0 auto;
  width: 85vw;
  height: 150px;
  max-width: 600px;
  overflow: hidden;
  border: 1px solid #979797;
}

.scrollaction li {
  font-size: 12px;
  line-height: 2em;
  padding-left: 10px;
/*  display: flex;
  justify-content: space-between; */
}

.scrollaction .region {
  width: 80px;
}
.scrollaction .name {
  width: 75px;
}
.scrollaction .title,
.scrollaction .region,
.scrollaction .name,
.scrollaction .date {
  display: inline-block;
  margin-right: 3px;
}



.scrollaction .title11,
.scrollaction .region11,
.scrollaction .name11,
.scrollaction .date11 {
  display: inline-block;
  margin-right: 3px;
  text-align: start;
}

.scrollaction .title11{
	/* width: 80px; */
}
.scrollaction .region11{
	width: 85px;
}
.scrollaction .name11{
	width: 70px;
}
</style>
